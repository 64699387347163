.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content:center;
  /* border:2px solid black; */
  /* align-items:center; */
  /* justify-content: flex-end; */
  /* overflow: hidden; */
  /* position: relative; */
}

.box1 {
  /* flex: 1; */
  /* display: flex;
  flex-direction: column; */
  height: 100%;
  width: 0%;
  /* border:2px solid black; */
  /* justify-content: center; */
  /* gap: 5rem; */
  /* position: relative; */
  /* border:2px solid black; */
  /* padding-right: 2rem; */
  /* background-image: url("../../Assets/Images/signin_background_latest.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat; */
}

.box2 {
 display: flex;
 align-items: center;
 justify-content: center;
 background-color: rgba(255, 255, 255, 0.9);
 box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
 width: 35%;
 max-width: 350px;
 min-width: 350px;
 border-radius: 2rem;
 padding: 1.25rem 2rem 1.25rem 2rem;
 min-height: 95%;
 height: fit-content;
}
.form{
  width: 100%;

  /* min-height: 100%; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; */
}

.inputs {
  width: 100%;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1.5rem;
}

.button {
  width: 100%;
  min-height: 50px;
  border-radius: 5px;
  border: none;
  background-color:#279ea0;
  color: #fff;
}

.button:hover {
  cursor: pointer;
}

.headerText {
  /* margin-top: -1rem; */
  color: #279ea0;
  font-weight: 500;
  font-size: "12px" !important;
  font-family: "Poppins";
}

.header {
  margin-bottom: 1.5rem;
  text-align: center;
}

.forgetPassword {
  color: #279f9f;
  font-weight: 500;
}

.forgetPassword:hover {
  cursor: pointer;
}

.logo {
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: 4rem;
}

.image {
  margin-left: 1rem;
}

.socialIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 2rem; */
  gap: 0.5rem;
  color: #279f9f;
  font-weight: 500;
  margin-top: -0.75rem;
}

.storeIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.5rem;
  gap: 1rem;
}

.iconContainer1 {
  /* border:2px solid black; */
  padding: 0.30rem 1.5rem 0.30rem 1.5rem;
  border-radius: 10rem;
  background-color: #DCDCDC;
}

.iconContainer2 {
  /* border:2px solid black; */
  padding: 0.07rem 1.25rem 0.07rem 1.25rem;
  border-radius: 10rem;
  background-color: #DCDCDC;
}

/* .blurryBg {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.63), rgba(0, 0, 0, 0.623)),
    url(../../Assets/Images/signin_background_latest.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}
.blurryBg::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(5px);
} */

@media (max-width: 767px) {
  .container {
    flex-direction: column;
  }

  .child1 {
    display: none;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .header {
    text-align: center;
  }

  .buttons {
    flex-direction: column;
    align-items: center;
  }

  .button {
    width: 100%;
  }
}



.logo1 {
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: 4rem;
}


.box5 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
  width: 35%;
  max-width: 550px;
  min-width: 550px;
  border-radius: 2rem;
  padding: 1.25rem 2rem 1.25rem 2rem;
  min-height: 95%;
  height: fit-content;
 }
 .button1 {
  width: 50%;
  min-height: 50px;
  border-radius: 5px;
  border: none;
  background-color:#279ea0;
  color: #fff;
}